<template>
  <div class="app" :class="[theme, { background: !isMobile }]">
    <router-view />
    <TicketsHistory v-if="userProfile.profile.id" />
    <Modal
      v-if="ticketCanceled"
      ref="modal"
      showFooter
      class="cancel-ticket-confirm"
      htmlId="cancel-confirm-modal"
      @closeModal="closeCancelTicketModal"
      @closeModalOutside="closeCancelTicketModal('outside')"
    >
      <template #header>
        {{ translations.general_info }}
      </template>
      <template #body>
        {{ ticketCanceledMessage }}
      </template>
      <template #footer>
        <button class="button" @click="closeCancelTicketModal">{{ translations.general_ok }}</button>
      </template>
    </Modal>
    <LandscapeNotification />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import applyPalette from '@nsftx/games-sdk-js/src/utility/applyPalette';
import { busService, integrator, webViewIntegration, terminalTicketHelper } from './utility';
import { merge, has, isArray, clone, startCase } from 'lodash';
import eventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import gtag from '@nsftx/games-sdk-js/src/utility/gtag';
import store from './store/index';
import NoSleep from 'nosleep.js';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'App',
  components: {
    TicketsHistory: defineAsyncComponent(
      () => import(/* webpackChunkName: "ticketsHistory" */ './components/TicketsHistory'),
    ),
    Modal: defineAsyncComponent(
      () => import(/* webpackChunkName: "modal" */ '@nsftx/games-sdk-js/src/components/Modal'),
    ),
    LandscapeNotification: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "LandscapeNotification" */ '@nsftx/games-sdk-js/src/components/LandscapeNotification'
        ),
    ),
  },
  data() {
    return {
      isLoggedIn: false,
      ticketCanceled: false,
      canceledTicket: {},
      check: null,
      checkTimeout: 10000,
    };
  },
  computed: {
    ...mapState(['gameState', 'bettingRules', 'isMobile', 'device', 'isTablet', 'jackpotState']),
    ...mapGetters([
      'config',
      'translations',
      'user/user',
      'isIsonis',
      'roundId',
      'getNumberColor',
      'isTerminal',
      'ajsDisabled',
      'theme',
    ]),
    ...mapGetters('gamesBetslip', ['ticket', 'totalPayment', 'futureRounds', 'selectedBet']),
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    isAndroidPlatform() {
      return webViewIntegration.isActive() && has(window, 'WebAppListener');
    },
    ticketCanceledMessage() {
      return (
        this.translations.general_ticket +
        ' ' +
        this.canceledTicket.id +
        ' ' +
        `${this.translations.general_successfully_cancelled}!`
      );
    },
    userProfile() {
      return this['user/user'];
    },
    isStandAlone() {
      return this.config.ui.config.mode === 'standalone';
    },
    isGravityGateway() {
      return this.config.messageProtocol.name === 'gravityGateway';
    },
  },
  methods: {
    ...mapActions([
      'getResults',
      'getStats',
      'setUserData',
      'setUserBalance',
      'setGameState',
      'updateRound',
      'notifications/clearNotification',
      'notifications/setNotification',
      'bettingDisable',
      'updateGameState',
      'clearSelection',
      'setIsBettingDisabled',
      'removeUser',
      'setTicketDetailsData',
      'setTerminalUserData',
      'clearTicketDetailsData',
      'setIsLoaded',
      'ticketDetailsRecheck',
      'setCurrentEventId',
      'setNextRoundTime',
      'setRoundInProgress',
      'timerTick',
      'setTicketToRebet',
      'setJackpotState',
    ]),
    ...mapActions('user', ['updateUser']),
    ...mapActions('gamesBetslip', [
      'setRoundNumber',
      'setIsPayinButtonDisabled',
      'getLastTickets',
      'setIsPayinInProgress',
      'clearBetslip',
      'removeBets',
      'updateBet',
      'userIsOperator',
    ]),
    ...mapActions({
      getUserBalance: 'user/getUserBalance',
    }),
    closeCancelTicketModal() {
      this.ticketCanceled = false;
    },
    setTheme() {
      const rootElement = document.documentElement;
      const { config } = this.config.ui;
      const baseColor = this.config.ui[config.baseColor];
      const theme = this.config.ui[config.theme];
      applyPalette.set(merge(theme, baseColor), rootElement);
    },
    load() {
      if (this.isAndroidPlatform) {
        window.WebAppListener.sendMessage('Slave.Loaded');
      }
      busService.sendMessage('Slave.Loaded', true);
    },
    authUser(auth) {
      store.dispatch(
        'user/updateUser',
        {
          auth: {
            token: auth.token,
            tpToken: auth.tpToken,
          },
          profile: auth.user || auth,
        },
        { root: true },
      );
      this.isLoggedIn = !!auth?.user?.logged || auth.id;
    },
    setupGtag(device) {
      if (this.config.ui.config.gtm && this.config.ui.config.gtm[device]) {
        const { code, auth, preview } = this.config.ui.config.gtm[device];
        gtag.setup(code, auth, preview);
        return;
      }
      gtag.setup();
    },
    updateTicketRound() {
      if (!this.ticket || !this.ticket.length) return;
      this.ticket.forEach((bet) => {
        this.setRoundNumber({
          betId: bet.id,
          roundNumber: this.roundId,
        });
      });
    },
    formatTicketBets() {
      const tickets = this.ticket.length ? this.ticket : [this.selectedBet];
      const result = tickets.map((n) => ({
        payin: n.stake ? Number(Number(n.stake).toFixed(2)) : Number(this.totalPayment),
        type: n.betId,
        value: n.category === 'Special' && n.betId !== 23 ? n.outcomeIndex : n.cleanOutcome,
        numEvents: n.numEvents,
      }));
      return result;
    },
    getPrintTemplate() {
      let template = '';
      const messages = this.translations;
      const { currency } = this.config;
      const bets = this.ticket.length ? this.ticket : [this.selectedBet];
      template += 'LuckyX;';
      template += "{{ ticketDateTimeUTC | date('d.m.Y H:i:s', false) }};";
      template += `${messages.general_pay_in} {{ payin | number_format(2, '.', ',') }}${currency} `;
      template += `${messages.general_payout} {{ payout | number_format(2, '.', ',') }}${currency} `;

      return (
        template +
        bets
          .map((bet) => {
            const betOutcome = isArray(bet.outcome) ? bet.outcome.join(',') : bet.outcome;
            const betMarket = !bet.color ? `${bet.market} ${bet.category ? bet.category : ''}` : bet.category;
            let body = '';
            let round = '';

            for (let i = 0; i < bet.numEvents; i += 1) {
              round = `${messages.general_round} : {{bets[0].eventId + ${i}}}`;
              body += `${round} `;
              body += `${betOutcome} `;
              body += `${betMarket} `;
            }
            return body;
          })
          .join('; ') +
        '{{ id }}'
      );
    },
    clearCheckTimeout() {
      clearTimeout(this.check);
    },
    checkTicket(requestUuid) {
      this.check = setTimeout(() => {
        store
          .dispatch('gamesBetslip/ticketCheck', requestUuid)
          .then((response) => {
            if (response.data.status.value.toUpperCase() !== 'PENDING') {
              const message = {
                message: this.translations.general_ticket_confirmed,
                status: 'positive',
                notificationTimeout: 1,
              };
              if (!this.isTerminal) {
                this.getLastTickets();
                store.dispatch('notifications/clearNotification').then(() => {
                  store.dispatch('notifications/setNotification', message);
                });
              } else {
                busService.sendMessage('Dialog.Show', {
                  message: this.translations['ticket.add_success'],
                  type: 'success',
                  delay: 3000,
                });
                busService.sendMessage('Tickets.Update', {
                  action: 'Add',
                  ticketData: response.data,
                  showMessage: false,
                });
              }
              this.setIsPayinButtonDisabled(false);
              this.bettingDisable(false);
              this.clearBetslip();
              store.dispatch('gamesBetslip/setIsPayinInProgress', false);
              store.dispatch('setPayinProgress', false);
            }
            this.clearCheckTimeout();
          })
          .catch((error) => {
            const notification = {
              type: 'warning',
              message: error.message,
            };

            if (this.isTerminal) {
              busService.sendMessage('Dialog.Show', { ...notification, delay: 3000 });
            } else {
              this.bettingDisable(false);
              store.dispatch('notifications/clearNotification').then(() => {
                store.dispatch('notifications/setNotification', { ...notification, notificationTimeout: 3000 });
              });
            }

            this.setIsPayinButtonDisabled(false);
            this.setIsPayinInProgress(false);
          });
      }, this.checkTimeout);
    },
    checkForRedirect() {
      const { clientplatform } = this.config;
      const redirectUrl = this.config.company.redirect.url;
      if (clientplatform && clientplatform === 'download') {
        window.location.replace(redirectUrl);
      }
    },
    updateTicketDataAfterRebet() {
      this.updateTicketRound();
      this.formatRebetTicket();
    },
    checkClientPlatformInfo() {
      const queryParams = this.getQueryParams();
      const { clientplatform } = queryParams;
      const redirectUrl = this.appConfig.company.redirect.url;
      if (clientplatform && clientplatform === 'download') {
        window.location.replace(redirectUrl);
      }
    },
    formatRebetTicket() {
      this.ticket.forEach((n) => {
        const bet = n;
        // Bet outcome comes from backend as a String with 2 spaces between values
        bet.outcome = bet.outcome.replaceAll('  ', ' ');
        bet.category = bet.type < 20 ? 'Standard' : 'Special';
        bet.cleanOutcome = bet.type !== 23 ? bet.outcome.split(' ') : Number(bet.outcome.split(' '));
        bet.betId = bet.type;

        // Update bet color
        const sameColor = bet.type !== 23 && bet.cleanOutcome.every((num) => num % 5 === bet.cleanOutcome[0] % 5);
        if (bet.cleanOutcome.length === 10 && sameColor && bet.type <= 10) {
          const hex = this.getNumberColor(bet.cleanOutcome[0]);
          const colorKey = this.bettingRules.bingoColors.findIndex((color) => color === hex);

          bet.market = this.translations[`luckyx_color_${colorKey}`];
          bet.color = hex;
        }

        this.updateBet(bet);
      });
    },
    toggleDeviceChannel(deviceId) {
      busService.addChannel('Device', deviceId);
    },
    togglePlayerChannel(userId) {
      if (userId) {
        busService.addChannel('Player', userId);
      } else {
        busService.removeChannel('Player', this.userProfile.profile.id);
      }
    },
    addAjsChannel() {
      if (this.jackpotState) {
        busService.addChannel('Jackpot', this.jackpotState.jackpotId);
      }
    },
    setPageTitle() {
      const { productFqn, applicationName } = this.config;
      document.title = `${startCase(productFqn)}: ${applicationName}`;
    },
  },
  mounted() {
    if (this.isMobile) {
      const noSleep = new NoSleep();
      let wakeLockEnabled = false;
      document.addEventListener(
        'click',
        function enableNoSleep() {
          document.removeEventListener('click', enableNoSleep, false);
          if (!wakeLockEnabled) {
            noSleep.enable();
            wakeLockEnabled = true;
          } else {
            noSleep.disable();
            wakeLockEnabled = false;
          }
        },
        false,
      );
    }
    (async () => {
      if (!this.ajsDisabled) {
        await this.setJackpotState();
        this.addAjsChannel();
      }
    })();
    this.load();
    const device = this.isMobile ? 'mobile' : this.isTablet ? 'tablet' : 'desktop';
    this.setupGtag(device);
    if (this.config.user && !this.isTerminal) {
      this.authUser(this.config.user);
      this.setUserBalance(this.config.user.balance);
    }
    this.setTheme();
    this.setPageTitle();
    if (this.isTerminal) {
      this.toggleDeviceChannel(this.device.deviceUuid);
      setInterval(this.timerTick, 1000);
    } else {
      if (this.config.platformConfig.clientType) {
        this.togglePlayerChannel(this.userProfile.profile.id);
        this.getLastTickets();
      }
    }
    window.addEventListener('Bus:Message', (event) => {
      const { eventName } = event.detail;
      const eventDetail = event.detail;
      const notification = {
        message: '',
        status: '',
        notificationTimeout: true,
      };
      this.setIsBettingDisabled(false);
      switch (eventName) {
        case 'SetState':
          if (this.isTerminal) {
            const countdownRequired = ['countdown', 'bettingDisabled'];
            if (countdownRequired.indexOf(eventDetail.type) !== -1) {
              const startingIn = eventDetail.delay - Math.round((eventDetail.sentTime - eventDetail.time) / 1000);
              this.setNextRoundTime(startingIn);
            } else {
              this.setRoundInProgress(true);
            }
          }
          this.setCurrentEventId(eventDetail.eventId);

          if (eventDetail.type) {
            this.setGameState(eventDetail);
          }
          if (eventDetail.type === 'countdown' || eventDetail.type === 'bettingDisabled') {
            this.updateRound(eventDetail.eventId);
          } else if (eventDetail.type === 'draw' || eventDetail.type === 'results') {
            this.updateRound(eventDetail.eventId + 1);
          }
          break;
        case 'StartEvent':
          if (this.isTerminal) {
            // If terminal ticket details are opened, recheck ticket to update it on start of the round
            this.ticketDetailsRecheck('0');
            this.setRoundInProgress(true);
          }
          this.setCurrentEventId(eventDetail.eventId);

          this.setGameState(eventDetail);
          this.updateRound(eventDetail.eventId + 1);
          this.updateTicketRound();
          this.setIsPayinButtonDisabled(false);
          if (this.isLoggedIn && !this.isTerminal) {
            setTimeout(() => {
              this.getLastTickets();
            }, 500);
          }
          break;
        case 'DrawResult': {
          const currentBallEventId = event.detail.eventId;
          const currentBallId = event.detail.id;
          const currentBalls = clone(this.gameState.balls);
          const isCurrentBallMissing = currentBalls?.findIndex((n) => n.id === currentBallId) < 0;

          if (isCurrentBallMissing) {
            currentBalls.push({
              ball: event.detail.ball,
              id: event.detail.id,
              eventId: currentBallEventId,
            });

            this.updateGameState({
              ball: event.detail.ball,
              currentBallId,
              balls: currentBalls,
              eventId: currentBallEventId,
              eventName: event.detail.eventName,
              sentTime: event.detail.sentTime,
            });
          }
          break;
        }
        case 'StopBetting':
          notification.message = this.translations.general_betting_disabled;
          notification.status = 'negative';
          store.dispatch('notifications/clearNotification').then(() => {
            store.dispatch('notifications/setNotification', notification);
          });
          this.setIsPayinButtonDisabled(true);
          this.setIsBettingDisabled(true);
          break;
        case 'SetResults':
          this.setGameState(eventDetail);
          this.getResults();
          this.getStats();
          break;
        case 'StartCountdown':
          if (this.isTerminal) {
            // If terminal ticket details are opened, recheck ticket to update it on end of the round
            this.ticketDetailsRecheck('8');
            this.setNextRoundTime(eventDetail.delay);
            this.setRoundInProgress(false);
          }

          if (this.isLoggedIn) {
            this.getLastTickets();
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }
          }
          break;
        case 'TicketUpdate':
          if (this.isUserLoggedIn && !this.isTerminal) {
            if (eventDetail.id && eventDetail.status.id === '0') {
              let message = this.translations.general_ticket_confirmed;
              let status = 'positive';
              notification.message = message;
              notification.status = status;

              // eslint-disable-next-line no-case-declarations
              const ticketsResolved = {
                action: 'Tickets.Resolved',
                data: {
                  accepted: true,
                  ticket: event.detail,
                },
              };
              // Send analytics message to parent frames
              if (this.isAndroidPlatform) {
                window.WebAppListener.sendMessage(JSON.stringify(ticketsResolved));
              }
              busService.sendMessage(ticketsResolved.action, ticketsResolved.data);
            }
            if (eventDetail.id && eventDetail.status.id === '1') {
              notification.message = this.translations.general_ticket_canceled;
              notification.status = 'neutral';
              this.ticketCanceled = true;
              this.canceledTicket = event.detail;
              eventBus.$emit('TicketCancelled');
              // this.getLastTickets();
            }
            if (this.isStandAlone && !this.isGravityGateway) {
              this.getUserBalance();
            }

            if (eventDetail.code) {
              notification.message = eventDetail.message;
              notification.status = 'negative';
              if (this.ticket.length === 1 && this.isQuickpayEnabled) {
                this.clearBetslip();
              }
            } else {
              this.removeBets();
            }
            this.getLastTickets();
            store.dispatch('notifications/clearNotification').then(() => {
              store.dispatch('notifications/setNotification', notification);
            });
          }
          if (this.isTerminal) {
            const ticket = JSON.parse(JSON.stringify(event.detail));
            ticket.ticketPin = event.detail.ticketPin;
            ticket.translation = 'Lucky X';
            // To capitalize ticket action value
            const action = ticket.action.charAt(0).toUpperCase() + ticket.action.slice(1);
            busService.sendMessage('Tickets.Update', {
              action: action,
              ticketData: ticket,
              showMessage: false,
            });
            terminalTicketHelper.handleTerminalTicket(ticket, event.detail);
            if (event.detail.code) {
              terminalTicketHelper.handleTicketUpdateError(ticket, event.detail.message);
            }
          }
          this.setIsPayinButtonDisabled(false);
          this.setIsPayinInProgress(false);
          this.bettingDisable(false);
          this.clearCheckTimeout();
          this.clearBetslip();
          break;
        case 'User.AuthorizationChanged': {
          if (this.isTerminal) {
            this.setTerminalUserData(eventDetail.data);
            return;
          }
          const authData = eventDetail.data.auth;
          if (authData) {
            const playerId = authData?.user?.id || null;
            this.togglePlayerChannel(playerId);
            this.authUser(authData);
          }
          if (authData.user && authData.user.id) {
            if (!this.config.user || !this.config.user.id) {
              this.setUserBalance(authData.user.balance);
            }
          } else {
            this.clearBetslip();
            this.removeUser();
          }
          break;
        }
        case 'User.ProfileChanged':
          this.setUserBalance(eventDetail.data.profile.balance);
          break;
        case 'User.BalanceChanged':
          this.setUserBalance(eventDetail.data.balance);
          break;
        default:
          break;
      }
    });
    window.addEventListener('visibilitychange', () => {
      if (this.isMobile || this.isTablet) {
        this.getLastTickets();
      }
    });
    eventBus.$on('TicketPayin', () => {
      this.setIsPayinButtonDisabled(true);
      this.setIsPayinInProgress(true);
      store
        .dispatch('gamesBetslip/payIn', {
          payload: this.formatTicketBets,
          additionalInfo: this.isIsonis
            ? {
                printTemplate: this.getPrintTemplate(),
                timezone: {
                  offset: parseInt(this.userProfile.profile.profile.timezoneOffset, 10),
                },
              }
            : null,
        })
        .then(async (response) => {
          await this.clearBetslip();
          if (this.isTerminal) {
            if (window.innerWidth <= 1260) eventBus.$emit('MinimizeBetslip');

            const config = {
              ticketGroup: this.config.productName,
            };
            const formatedPayload = response;
            formatedPayload.ticket.stake = response.ticket.payin;
            formatedPayload.ticket.config = config;
            if (formatedPayload.ticket.payin > this['user/user'].balance) {
              busService.sendMessage('Dialog.Show', {
                message: this.translations['notifications.insufficient_funds'],
                type: 'warning',
                delay: 3000,
              });
              return;
            }
            busService.sendMessageAsync('Tickets.Pay', formatedPayload);
          } else {
            this.checkTicket(response.data.requestUuid);
            const message = {
              message: this.translations.general_ticket_resolving,
              status: 'neutral',
            };
            store.dispatch('notifications/setNotification', message);
            this.setIsPayinButtonDisabled(true);
            this.setIsPayinInProgress(true);
            this.bettingDisable(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            const errorData = error.response.data;
            const message = {
              message: errorData.message,
              status: 'negative',
            };
            store.dispatch('notifications/setNotification', message);
          }
          return error;
        });
      this.clearSelection();
    });
    eventBus.$on('TicketCancel', (ticket) => {
      busService.sendMessageAsync('Tickets.Cancel', {
        ticket,
      });
    });
    eventBus.$on('TicketPayout', (ticket) => {
      busService.sendMessageAsync('Tickets.Payout', {
        ticket,
      });
    });
    eventBus.$on('ReturnToLobby', () => {
      busService.sendMessage('UI.Show', {
        name: 'Lobby',
      });
      if (this.isAndroidPlatform) {
        window.WebAppListener.sendMessage('Navigation.Back');
      }
    });
    eventBus.$on('LoginRequired', () => {
      busService.sendMessage('User.LoginRequired', {
        type: 'loginRequired',
      });
      if (this.isAndroidPlatform) {
        window.WebAppListener.sendMessage('User.LoginRequired');
        // Playtech requires this on Android platform
        this.checkForRedirect();
      }
      integrator.sendMessage({
        type: 'loginRequired',
      });
    });
    eventBus.$on('TicketRebet', this.updateTicketDataAfterRebet);
    eventBus.$on('LoadLastTickets', this.getLastTickets);
    eventBus.$on('CheckTicket', (requestUuid) => this.checkTicket(requestUuid));
    eventBus.$on('RebetTicket', (ticket) => {
      busService.sendMessage('UI.Show', {
        name: ['BettingArea'],
      });
      this.setTicketToRebet(ticket);
    });
    eventBus.$on('CloseTicketDetails', () => {
      this.setIsLoaded(false);
      this.clearTicketDetailsData();
      busService.sendMessage('UI.Show', {
        name: ['BettingArea'],
      });
    });
  },
  watch: {
    roundId(newValue) {
      this.updateTicketRound(newValue);
    },
    isLoggedIn(value) {
      if (value && !this.isTerminal) {
        this.getLastTickets();
      }
    },
  },
};
</script>

<style lang="scss">
@use './assets/style/helpers/_modal.scss';
* {
  margin: 0;
  padding: 0;
  outline-style: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html,
body {
  background-color: var(--bg-color-1);
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  -ms-overflow-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling */
  text-size-adjust: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
#app {
  font-family: 'Roboto', sans-serif;
}
.app {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: auto;
  // default style
  color: var(--text-primary-1);
  font-size: var(--font-size-medium, 14px);

  &.dark {
    background-image: url('assets/images/game-bg-dark.jpg');
  }

  &.light {
    background-image: url('assets/images/game-bg-light.jpg');
  }

  .cancel-ticket-confirm {
    .modal {
      background-color: var(--card);
      height: 182px;

      .modal-header,
      .modal-body {
        color: var(--text-primary-1);
      }

      .modal-header {
        font-size: 16px;
      }

      .modal-body {
        font-size: 14px;
        .modal-actions {
          display: none;
        }
      }
      .modal-footer {
        padding-right: 43px;
        position: relative;
      }
      .button {
        background: transparent;
        border: 0;
        outline: 0;
        font-size: 14px;
        color: var(--secondary);
        text-transform: uppercase;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        padding: 31px 0 27px 0;
      }
    }
  }
}

@media (max-width: 660px) {
  .app {
    .cancel-ticket-confirm {
      background-color: var(--overlay-lock);
      .modal {
        background-color: var(--card);
        color: var(--text-primary-1);
        height: 182px !important;

        .modal-header {
          background-color: var(--card);
          padding: 24px 24px 30px 24px;
        }

        .modal-body {
          position: relative;
          padding: 0 24px;
          .modal-actions {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            padding-right: 43px;
          }
        }

        .modal-footer {
          display: none;
        }
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.1) {
  .ticket-wrapper {
    zoom: 0.9;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  .ticket-wrapper {
    zoom: 0.8;
  }
}
</style>
